/* SpecializTea.css */
.specializ-tea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 80px; /* Added more padding for larger screens */
    font-family: 'Libre Baskerville', serif;
    animation: fadeIn 1s ease-in-out;
    background-color: black; /* Optional background color */
    gap: 2rem; /* Added gap between sections */
  }
  
  .left-section {
    flex: 1;
    padding-right: 40px; /* Increased padding on the right for better spacing */
  }
  
  .title {
    font-size: 2.5rem; /* Increased font size */
    margin-bottom: 20px; /* Adjusted margin */
    color: #ffff; /* Changed to a dark color for better contrast */
    transition: color 0.3s, transform 0.3s; /* Added transform for hover effect */
  }
  
  .title:hover {
    color: #3d3796; /* Color change on hover */
    transform: scale(1.05); /* Slight scaling on hover */
  }
  
  .description {
    font-size: 1.25rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tea-image {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1); 
    transition: transform 0.5s, box-shadow 0.5s; 
  }
  
  .tea-image:hover {
    transform: scale(1.05); 
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3); 
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  
  @media screen and (max-width: 1024px) {
    .specializ-tea {
      flex-direction: column; 
      padding: 30px; 
    }
  
    .left-section {
      padding-right: 0; 
      text-align: center;
    }
  
    .title {
      font-size: 2rem; 
    }
  
    .description {
      font-size: 1.1rem; 
    }
  }
  
  @media screen and (max-width: 768px) {
    .specializ-tea {
      padding: 20px; 
    }
  
    .title {
      font-size: 1.8rem; 
    }
  
    .description {
      font-size: 1rem; 
    }
  }
  
  @media screen and (max-width: 480px) {
    .specializ-tea {
      padding: 15px; 
    }
  
    .title {
      font-size: 1.5rem; 
    }
  
    .description {
      font-size: 0.9rem; 
    }
  
    .tea-image {
      width: 100%;
      height: auto;
    }
  }
  