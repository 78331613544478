/* 
.cart-container {
  padding: 1rem;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
  color: white;
}

.cart-title {
  color: white;
  margin-top: 10rem;
}

.empty-cart-text {
  font-size: 1.5rem;
  text-align: center;
  color: #666;
  margin-top: 2rem;
}

.empty-cart-text a {
  color: #007bff;
  font-size: 1.2rem;
  text-decoration: none;
}

.empty-cart-text a:hover {
  text-decoration: underline;
}

.cart-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
}

.cart-item {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #000000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
}

.cart-item-details h3 {
  margin: 0;
  font-size: 1.2rem;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 1rem;
}

.quantity-button {
  border: 1px solid #ddd;
  background: #fffefe;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.quantity-button:hover {
  background: #f0f0f0;
}

.quantity {
  margin: 0 0.5rem;
  font-size: 1.2rem;
}

.remove-button {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-left: auto; 
}

.remove-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}

.order-now-button {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.order-now-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}

.add-address-button {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.add-address-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}

.save-address-button {
  background-color: #16a085;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.save-address-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}

.cart-item-details .item-price {
  margin-top: 10px;
  font-weight: bold;
}

.cart-summary {
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  background: #000000;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.cart-summary h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

.store-selector {
  margin: 20px 0;
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.store-selector label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

.store-selector select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #1abc9c;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  width: 100%;
  max-width: 360px;
}

.store-selector option {
  padding: 8px;
}

.store-selector select:hover,
.store-selector select:focus {
  border-color: #1abc9c;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.25);
}

.address-section {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  background: #181818;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.address-form {
  margin-top: 10px;
}

.address-form input,
.address-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.address-card {
  background: #000000;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.address-card p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .cart-item-image {
    width: 80px;
    height: auto;
  }

  .cart-item-details {
    flex-direction: column;
  }

  .quantity-control {
    align-items: center;
  }

  .quantity-button {
    width: 100%;
  }

  .cart-summary {
    font-size: 0.9rem;
  }

  .add-address-button {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .cart-item-image {
    width: 80px;
    height: auto;
  }

  .cart-summary h3 {
    font-size: 1rem;
  }

  .order-now-button {
    width: 100%;
  }

  .address-form input,
  .address-form textarea {
    padding: 8px;
    font-size: 0.9rem;
  }

  .address-form button {
    width: 100%;
    font-size: 0.9rem;
  }
} */

/* General Styles */
body {
  font-family: Arial, sans-serif;
  color: #fff;
  background-color: black;
  margin: 0;
  padding: 0;
}

.cart-container {
  padding: 1rem;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: black;
}

.cart-title {
  color: #fff;
  margin-top: 10rem;
}

.empty-cart-text {
  font-size: 1.5rem;
  text-align: center;
  color: white;
  margin-top: 2rem;
}

.empty-cart-text a {
  color: #007bff;
  font-size: 1.2rem;
  text-decoration: none;
}

.empty-cart-text a:hover {
  text-decoration: underline;
}

/* Cart Items */
.cart-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  color: white;
}

.cart-item {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: white;
  margin-bottom: 1rem;
}

.cart-item-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
}

.cart-item-details h3 {
  margin: 0;
  font-size: 1.2rem;
}

.item-price {
  margin-top: 10px;
  font-weight: bold;
}

/* Quantity Control */
.quantity-control {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  gap: 1rem;
}

.quantity-button {
  border: 1px solid #ddd;
  background: #fffefe;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  color: black;
}

.quantity-button:hover {
  background: #f0f0f0;
}

.quantity {
  margin: 0 0.5rem;
  font-size: 1.2rem;
}

/* Buttons */
button {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.remove-button,
.order-now-button,
.add-address-button,
.save-address-button {
  background-color: #16a085;
  color: #fff;
}

.remove-button {
  margin-left: auto;
}

.remove-button:hover,
.order-now-button:hover,
.add-address-button:hover,
.save-address-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}

.save-address-button {
  color: white;
}

/* Cart Summary */
.cart-summary {
  width: 100%;
  max-width: 800px;
  padding: 1rem;
  background: #000;
  color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-weight: bold;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.cart-summary h3 {
  margin: 0.5rem 0;
  font-size: 1.2rem;
  display: flex;
  justify-content: space-between;
}

/* Store Selector */
/* .store-selector {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.store-selector label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: white;
}

.store-selector select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #1abc9c;
  border-radius: 4px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  width: 100%;
  max-width: 360px;
}

.store-selector select:hover,
.store-selector select:focus {
  border-color: #1abc9c;
  box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.25);
} */

.store-selection {
  margin: 1rem 0;
}

.store-selection h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #ccc; /* Light color for dark backgrounds */
}

.store-selection select {
  width: 100%;
  padding: 0.5rem 1rem;
  border: 1px solid #555;
  border-radius: 0.5rem;
  background-color: #333; /* Dark background */
  font-size: 1rem;
  color: #eee; /* Light text color */
  transition: border-color 0.3s, background-color 0.3s;
}

.store-selection select:focus {
  border-color: #007bff; /* Primary color */
  background-color: #444; /* Slightly lighter background on focus */
  outline: none;
}

.store-selection option {
  background-color: #333;
  color: #eee;
  padding: 0.5rem;
}



/* Address Section */
.address-section {
  margin-bottom: 1rem;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  background: #181818;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.address-form {
  margin-top: 10px;
}

.address-form input,
.address-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.address-card {
  background: #000;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.address-card p {
  margin: 5px 0;
}

/* Media Queries */
@media (max-width: 768px) {
  .cart-item-image {
    width: 80px;
  }

  .cart-item-details {
    flex-direction: column;
  }

  .quantity-control {
    align-items: center;
  }

  .quantity-button {
    width: 100%;
  }

  .cart-summary {
    font-size: 0.9rem;
  }

  .add-address-button {
    width: 100%;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .cart-summary h3 {
    font-size: 1rem;
  }

  .order-now-button,
  .add-address-button,
  .save-address-button {
    width: 100%;
  }

  .address-form input,
  .address-form textarea {
    padding: 8px;
    font-size: 0.9rem;
  }

  .address-form button {
    width: 100%;
    font-size: 0.9rem;
  }
}
