@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

@media (pointer: fine) {
  .cursor {
    z-index: 1001;
    pointer-events: none;
    position: absolute;
    padding-top: -2px;
    rotate: 136deg;
    height: 10px;
    width: 10px;
  }
  .cursor div {
    background-color: green;
    height: 10px;
    width: 10px;
  }
  body:active {
    .cursor div {
      background-color: #1c69ed;
    }
  }
}


.modal-overlay {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal-overlay.active {
  opacity: 1;
}

.modal-container {
  transform: scale(0.9);
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.modal-container.active {
  transform: scale(1);
  opacity: 1;
}
