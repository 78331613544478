.food-quality-container {
    width: 100%;
    background-color: black;
    padding: 7rem 6rem;
    box-sizing: border-box;
    overflow: hidden;
}

.food-quality-content {
    max-width: 1200px;
    margin: 0 auto;
}

.food-quality-title {
    font-size: 3rem;
    font-family: "'Libre Baskerville', serif, sans-serif";
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4rem;
    position: relative;
}

.food-quality-title::after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: 50%;
    width: 80px;
    height: 4px;
    background-color: rgb(18, 120, 204);
    transform: translateX(-50%);
    transition: width 0.3s ease, background-color 0.3s ease;
}

.food-quality-title:hover::after {
    width: 120px;
    background-color: green;
}

.food-quality-details {
    display: flex;
    align-items: center;
    gap: 4rem;
    flex-wrap: wrap;
}

.food-quality-image-container {
    flex: 1;
    max-width: 700px; 
    height: 670px;    
    overflow: hidden;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.5s ease;
    position: relative;
}

.food-quality-image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(45deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.food-quality-image-container:hover {
    transform: translateY(-15px) rotate(5deg);
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.5);
}

.food-quality-image-container:hover::before {
    opacity: 1;
}

.food-quality-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s ease;
}

.food-quality-image-container:hover .food-quality-image {
    transform: scale(1.2);
}

.food-quality-description-container {
    flex: 1;
    min-width: 240px;  
}

.food-quality-description {
    font-size: 1.4rem; 
    font-family: 'Baskerville', sans-serif;
    color: #f0f0f0;
    line-height: 2;   
    text-align: justify;
    padding: 20px;      
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

.food-quality-description:hover {
    background-color: rgba(255, 255, 255, 0.15);
    transform: translateY(-10px);
    box-shadow: 0 10px 25px rgba(255, 255, 255, 0.15);
}

@media screen and (max-width: 1024px) {
    .food-quality-image-container {
        max-width: 100%;
        height: 350px;
    }

    .food-quality-details {
        flex-direction: column;
    }

    .food-quality-description-container {
        margin-top: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .food-quality-container {
        padding: 5rem 3rem;
    }

    .food-quality-title {
        font-size: 2.5rem;
    }

    .food-quality-image-container {
        height: 300px; 
    }

    .food-quality-description {
        font-size: 0.85rem;  
    }
}

@media screen and (max-width: 480px) {
    .food-quality-container {
        padding: 3rem 2rem;
    }

    .food-quality-title {
        font-size: 2rem;
    }

    .food-quality-image-container {
        height: 250px;
    }

    .food-quality-description {
        font-size: 0.8rem; 
    }
}
