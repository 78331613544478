.our-menu-container {
  display: flex;
  background-color: #000000;
  color: #fff;
  min-height: 60vh;
  padding: 2rem 0;
}

.menu-sidebar {
  width: 30%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #0000;
  color: #fff;
}

.menu-title1 {
  font-family: 'Baskerville';
  font-size: 3.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  border-bottom: 4px solid #e44646;
  padding-bottom: 1rem;
  color: #ffffff;
}

.menu-description {
  font-size: 1.1rem;
  line-height: 1.6;
}

.menu-content {
  width: 70%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.menu-cards-container {
  width: 100%;
  overflow: hidden;
}

.menu-cards {
  display: flex;
  transition: transform 0.3s ease;
}

.menu-card {
  flex: 0 0 calc(33.333% - 2rem);
  background: #1a1a1a;
  border-radius: 15px;
  margin: 0 1rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: transform 2.3s ease, box-shadow 2.3s ease;
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow: hidden;
  position: relative;
}

.menu-card:hover {
  transform: scale(1.05); /* Slight zoom */
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.4);
}

.card-image-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.card-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.menu-card:hover .card-image {
  transform: scale(1.05);
}

.card-title {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  color: white;
  padding: 1rem;
  margin: 0;
  font-size: 2.2rem;
  text-align: left;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.get-more-button {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: #e44646;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.get-more-button:hover {
  background-color: #e44646;
  transform: translateY(-2px);
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  z-index: 10;
  font-size: 1.5rem;
}

.carousel-button:hover {
  background-color: rgba(255, 255, 255, 0.3);
  transform: translateY(-50%) scale(1.1);
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

@media (max-width: 1200px) {
  .our-menu-container {
    flex-direction: column;
  }

  .menu-sidebar,
  .menu-content {
    width: 100%;
  }

  .menu-card {
    flex: 0 0 calc(50% - 2rem);
  }
}

@media (max-width: 768px) {
  .menu-card {
    flex: 0 0 calc(100% - 2rem);
  }

  .menu-title {
    font-size: 3.5rem;
  }
}


@media (max-width: 768px) {
  .menu-card {
    flex: 0 0 100%;
    margin: 0;
  }

  .menu-cards-container {
    width: 100%;
    padding: 0 1rem;
  }

  .menu-cards {
    display: flex;
    transition: transform 0.5s ease;
  }

  .carousel-button {
    top: auto;
    bottom: -60px;
    transform: none;
  }

  .carousel-button.prev {
    left: calc(50% - 60px);
  }

  .carousel-button.next {
    right: calc(50% - 60px);
  }

  .menu-content {
    padding-bottom: 80px;
  }
}