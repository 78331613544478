@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Poppins:wght@300;400;600&display=swap');

.why-choose-us {
  background-color: #0a0a0a;
  padding: 8rem 0;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.why-choose-us::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.9;
  pointer-events: none;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 2rem;
}

.content-wrapper {
  display: flex;
  gap: 6rem;
  align-items: center;
}

.text-content {
  flex: 1;
}

.small-title {
  font-family: 'Amiri', serif;
  color: #e44646;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  display: inline-block;
}

.small-title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 50px;
  height: 2px;
  background-color: #e44646;
  transition: width 0.3s ease;
}

.text-content:hover .small-title::after {
  width: 100%;
}

.big-title {
  font-family: 'Amiri', serif;
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  color: #fff;
  line-height: 1.2;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.description {
  font-family: 'Poppins', sans-serif;
  font-size: 1.1rem;
  line-height: 1.8;
  color: #ccc;
  margin-bottom: 3rem;
}

.features {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 3rem;
}

.feature {
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.05);
  position: relative;
  overflow: hidden;
}

.feature::before {
  content: '';
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(228, 70, 70, 0.1), transparent);
  transition: top 0.3s ease;
}

.feature:hover::before {
  top: 0;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(228, 70, 70, 0.1);
}

.icon {
  font-size: 2.5rem;
  color: #e44646;
  transition: transform 0.3s ease;
}

.feature:hover .icon {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% { transform: translate3d(-1px, 0, 0); }
  20%, 80% { transform: translate3d(2px, 0, 0); }
  30%, 50%, 70% { transform: translate3d(-4px, 0, 0); }
  40%, 60% { transform: translate3d(4px, 0, 0); }
}

.feature p {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  color: #aaa;
}

.explore-btn {
  background-color: #e44646;
  color: rgb(37, 39, 40);
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.explore-btn::before {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  background: white;
  transform: rotate(45deg);
  transition: all 0.5s ease;
  z-index: -1;
}

.explore-btn:hover::before {
  top: -50%;
  left: -50%;
}

.explore-btn:hover {
  color: #000;
  box-shadow: 0 5px 15px rgba(228, 70, 70, 0.3);
}

.image-content {
  flex: 1;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 1rem;
  height: 600px;
}

.image-grid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.5s ease, filter 0.5s ease;
  filter: grayscale(30%);
}

.image-grid img:hover {
  transform: scale(1.1) rotate(5deg);
  filter: brightness(1.2) contrast(1.1) grayscale(0%);
  z-index: 2;
}

.img-1 {
  grid-area: 1 / 1 / 5 / 4;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

.img-2 {
  grid-area: 1 / 4 / 4 / 7;
  clip-path: polygon(15% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.img-3 {
  grid-area: 5 / 1 / 7 / 3;
  clip-path: polygon(0 15%, 100% 0%, 100% 100%, 0% 100%);
}

.img-4 {
  grid-area: 4 / 4 / 7 / 7;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 15% 100%);
}

.img-1:hover, .img-2:hover, .img-3:hover, .img-4:hover {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@media (max-width: 1200px) {
  .content-wrapper {
    flex-direction: column;
  }

  .image-content {
    width: 100%;
  }

  .image-grid {
    height: 400px;
  }
}

/* Desktop View */
.desktop-view {
  display: block;
}

.mobile-view {
  display: none;
}

/* Mobile View */
@media (max-width: 768px) {
  .desktop-view {
    display: none;
  }

  .mobile-view {
    display: block;
    position: relative;
    text-align: center;
  }

  .carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .carousel-controls {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }

  .prev-btn, .next-btn {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
  }

  .prev-btn:hover, .next-btn:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
}