.banner8 {
  margin-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 47vh;
  flex-shrink: 0;
  border-radius: 10px;
  background: url("../../assets/aboutus/contactbg.jpg");
  background-repeat: no-repeat;
  background-position-x: center;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  position: relative;
}
.overlay8 {
  border-radius: 10px;
  position: absolute;
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 100%;
  height: 100%;

  z-index: 0;
}
.title8 {
  z-index: 1;
}
.contact-heading-section {
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}
.contact-us-heading {
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  background: linear-gradient(90deg, #fd00dc -25.78%, #00fff0 190.27%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contact-heading {
  color: #ffffff;
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  text-transform: capitalize;
}

.contact-heading-desc {
  color: #d84d4d;
  text-align: center;
  font-family: Montserrat;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
}

.contact-card-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
  padding: 15px;
}

.contact-card {
  border-radius: 21px;
  border: 1px solid #d1d1d1;
  background: #000000;
  color: #000000;
  width: 335px;
  height: 296px;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 0 auto;
}

@media (max-width: 768px) {
  .contact-card-group {
    flex-direction: column;
    align-items: center;
  }
}
.contact-card-title,
.contact-card-detail {
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;

  text-transform: capitalize;
}
.contact-card-detail-lower {
  color: #ffffff;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-decoration-line: underline;
}
.contact-card-detail {
  text-decoration-line: underline;
}

.contact-card-title-desc {
  color: #616161;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-transform: capitalize;
}
.contact-card-icon {
  width: 70px;
  height: 70px;
  border-radius: 21px;
  border: 1px solid #efeeee;
  background: #9d9494;
  backdrop-filter: blur(7.5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
}
@media screen and (max-width: 945px) {
  .contact-card {
    margin-left: auto;
    margin-right: auto;
  }
  .contact-heading {
    font-size: 30px;
  }
  .contact-heading-desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .contact-heading {
    font-size: 25px;
  }
  .contact-heading-desc {
    font-size: 16px;
  }
}
