.container1 {
  width: 100vw; /* Ensure the container takes the full viewport width */
  padding: 0; /* Remove padding to prevent extra space */
  margin: 0; /* Remove margin */
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Ensures padding is included in width calculation */
}


.section-heading {
  text-align: center;
  margin-bottom: 16px;
}

.heading-text {
  font-size: 24px;
  font-weight: bold;
}

.heading-desc {
  font-size: 16px;
  color: #666;
}

.button-group {
  margin-bottom: 16px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  justify-content: center;
  border-bottom: 2px solid #ddd;
  /* Adjusted to fit the container */
  max-width: 600px; /* Limit the width on larger screens */
}

.button {
  padding: 12px 24px;
  margin: 0 8px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: #374151;
  border-bottom: 2px solid transparent;
  transition: background-color 0.3s, color 0.3s, border-bottom 0.3s;
  font-size: 16px;
  font-weight: bold;
}

.button:hover {
  background-color: #f1f1f1;
}

.button.active {
  background-color: white;
  color: #3b82f6;
  border-bottom: 2px solid #3b82f6;
}

.section-content3 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.content3 {
  text-align: center;
  margin: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
}

.v-icon img {
  width: 70px;
  height: 70px;
}

.content3-heading {
  font-size: 20px;
  font-weight: bold;
  margin-top: 16px;
}

.line {
  width: 50px;
  height: 4px;
  background-color: #3b82f6;
  margin: 8px auto;
}

.content3-detail {
  font-size: 16px;
  color: #ffffff;
  margin-top: 8px;
  width: 100%; /* Make it responsive */
}

.pattern-vision {
  width: 900px;
  height: 550px;
  background-repeat: no-repeat;
  background-position-x: -90px;
  background-blend-mode: multiply;
  /* background-position-y:-80px ; */
  position: absolute;
  background-size: contain;
  margin-top: 1700px;
  /* transform: translateY(-3253.82px); */
  right: -350px;
  /* right: 0px; */
}

@media (max-width: 768px) {
  .pattern-vision {
    margin-top: 2500px;
  }
  .heading-text {
    font-size: 20px;
  }
  .v-icon img {
    width: 50px;
    height: 50px;
  }

  .heading-desc {
    font-size: 14px;
  }

  .button-group {
    flex-direction: column;
    align-items: stretch;
  }

  .button {
    width: 100%;
    margin: 4px 0;
  }

  .section-content3 {
    flex-direction: column;
    align-items: center;
  }

  .content3 {
    max-width: 100%;
    margin: 8px 0;
  }
}

@media (max-width: 480px) {
  .heading-text {
    font-size: 18px;
  }
  .v-icon img {
    width: 40px;
    height: 40px;
  }

  .heading-desc {
    font-size: 12px;
  }

  .button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .content3-heading {
    font-size: 18px;
  }

  .content3-detail {
    font-size: 14px;
  }
}
