.home {
  top: 0;
  width: 100%;
  background-color: #000000;
  color: white;
}

.hero {
  width: 100%;
  height: 100vh;
  position: relative;
}

#video {
  width: 600px;
  padding-top: 100px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-self: center;
  object-fit: cover;
  z-index: -1;
  background: #000000;
}

.overlay10 {
  top: 0;
  background-color: rgba(0, 0, 0, 0.3) !important;
  position: absolute;
  width: 100%;
  height: 100%;
}

.hero-slider-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.hero-slider-item {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.slider-content {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  margin: auto;
  padding: 4rem;
  gap: 1rem;
  color: white;
  align-items: center;
}

.slider-heading {
  color: #fff;
  text-align: center;
  font-family: 'Libre Baskerville', serif, sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin-top: 30vh;

  padding-left: 2rem;
  padding-right: 2rem;
}

.slider-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 189px;

  border: 2px solid #fff;
  height: 58px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;

  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 5rem;
}

.slider-btn-text {
  color: #fff;
  font-size: 15px;
  font-family: "Open Sans";
  text-transform: uppercase;
  text-decoration: none;
}

.button-hero {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-btn:hover .slider-btn-text {
  color: #949494 !important;
}

.slider-btn:hover {
  border: 2px solid #b6b6b6 !important;
}

.home-slider {
  z-index: 0;
  width: 100%;
  position: relative;
  height: 100vh;
  top: 0;
}

.hero-slider-content .carousel-control-prev-icon {
  display: none;
}

.hero-slider-content .carousel-control-next-icon {
  display: none;
}


.content-btn {
  color: #fff;
  width: 237px;
  padding: 12px 24px; 
  height: 56px;
  border: none;
  text-align: center;
  font-family: "Open Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 33px;
  text-transform: capitalize;
  border-radius: 42px;
  transition: 0.4s ease-in-out;
  background-color: #16a085;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  position: relative;
  overflow: hidden;
}
.content-1-details a {
  text-decoration: none;
}

.arrow-icon {
  transition: transform 0.3s ease;
}

.content-btn::before {
  content: "";
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transition: top 0.3s ease-in-out;
  z-index: 0;
}

.content-btn:hover::before {
  top: 0;
}

.content-btn:hover {
  color: #000;
}

.content-btn:hover .arrow-icon {
  transform: translateX(5px);
}

.content-btn span {
  position: relative;
  z-index: 1;
}

.content-btn:hover span {
  color: #000;
}


.content-1-details {
  margin-bottom: 2rem;
  z-index: 9991;
  position: relative;
}

.content-1 {
  margin: 4.5rem 1rem;
}

.heading {
  color: #28245f;

  font-family: 'Libre Baskerville', serif, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  transition: opacity 0.5s linear;
  text-transform: uppercase;
}

.para {
  color: #eceef2;
  font-family: "'Libre Baskerville', serif, sans-serif";
  font-size: 25px;
  font-style: normal;
  font-weight: 200;
  text-transform: capitalize;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



.heading-text {
  color: #ffff;
  text-align: center;
  font-family: 'Libre Baskerville', serif, sans-serif;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  text-transform: capitalize;
}

.heading-desc {
  color: #d84d4d;
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  text-transform: capitalize;
}

.view-btn {
  color: #000000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
  justify-content: center;

  text-transform: capitalize;
  border-radius: 10px;

  background: #e2e2e2;
  width: 100%;
  height: 56px;

  border: none;
  transition: 0.2s ease-in-out;
}

.view-btn a {
  text-decoration: none;
  color: #eee2e2;
}

.view-btn:hover a {
  color: white;
  border-radius: 10px;
}

.view-btn:hover {
  opacity: 1;
  color: white;
  background: linear-gradient(179deg, #06f 0.76%, rgba(0, 132, 227, 0) 241.69%);
  transition: 0.2s ease-in-out;
  transform: scale(1.1);
}

.card {
  gap: 30px;
  width: 295px;
  align-items: center;
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 10px;
  background: #fff;
  box-shadow: 1px 6px 9px 0px rgba(0, 0, 0, 0.25);
}

.dot {
  position: absolute;
  z-index: 1;
}

.card-img {
  z-index: 2;
  position: relative;
  color: white;
}

.home-section2 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.card-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;
  margin-top: 3rem;
}

.card-text {
  color: #1d1d1d;
  text-align: center;
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px;
  /* 137.5% */
  text-transform: uppercase;
}

/* slider css */

.home-section4 {
  margin: 2rem 5rem;
  padding: 2rem 2rem;
}

.client-slider {
  margin-top: 4rem;
  width: 100%;
}

.slider:hover img {
  mix-blend-mode: normal;
  opacity: 1;
  transition: 0.2s ease-in-out;
}

.slider img {
  max-width: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  mix-blend-mode: luminosity;
  transition: 0.4s ease-in-out;
}

.slick-slide {
  margin: 0 27px;
}

.slick-list {
  height: 200px;
}

.slider_client {
  display: flex !important;
  /* background-image: url("../../assets/client-logo/client-bg.jpeg"); */
  background: black;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 20px 50px;
  padding: 20px;
  width: 100%;
  height: 150px;
  position: relative;
  border-radius: 5px;
  /* background: #fff; */
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.12);
}

.slider:hover {
  border: 1px solid #c762ba;
}

.content-1-img {
  width: 100%;
  height: 500px;
}

.content-1-img-section .carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border: 1px solid transparent !important;
  border-radius: 10px;
}

.content-1-img-section .carousel-control-prev-icon {
  display: none;
}

.content-1-img-section .carousel-control-next-icon {
  display: none;
}

.content-1-img-section {
  margin: 4.5rem 1rem;
}

.content-1-img-section img {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.content-1-img-section .carousel {
  width: 100%;
  max-width: 100%;
}
.map-section {
  margin-top: -25%;
  margin-bottom: -25%;
  width: 100%;
}
.pattern-left-home {
  margin-right: auto !important;
  width: 608px;
  height: 1205px;
  background-repeat: no-repeat;
  /* background-position-x: 90px; */
  background-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  background-size: cover;
  left: 0px;
  transform: rotate(45deg);
}

/* .pattern-about {
  margin-left: auto !important;
  width: 608px;
  height: 800px;
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  background-size: cover;
  right: 0px;
} */
.pattern-about-left {
  margin-left: auto !important;
  width: 608px;
  height: 800px;
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-blend-mode: multiply;
  opacity: 0.5;
  position: absolute;
  background-size: cover;
  left: 0px;
  /* right: 0px; */
}
.pattern-footer {
  margin-left: auto ;
  width: 940px;
  height: 800px;
  background-repeat: no-repeat;
  background-position-y: 90px;
  background-blend-mode: multiply;
  opacity: 0.3;
  margin-top: 5050px;
  /* background-position-y:-80px ; */
  position: absolute;
  background-size: cover;

  left: 0px;
  right: 0px;
 
}
.pattern-about {
  margin-left: auto ;
  width: 608px;
  height: 800px;
  background-repeat: no-repeat;
  background-position-y: 90px;
  background-blend-mode: multiply;
  opacity: 0.3;
  margin-top: 1450px;
  /* background-position-y:-80px ; */
  position: absolute;
  background-size: cover;

  left: 0px;
  right: 0px;
 
}

@media screen and (min-width: 945px) {
  .card-section {
    justify-content: space-around;
  }
}

@media screen and (max-width: 1100px) {
  .slider-heading {
    font-size: 40px;
  }

  .slider-btn {
    font-size: 13px;
    width: 169px;
    height: 48px;
  }

  .slider img {
    max-width: 80px;
  }

  .slider {
    height: 100px;
  }

  .slick-slide {
    margin: 0 10px;
  }

  /* .card-section {
    position: relative;
  } */
  .card {
    margin: 0 auto;
  }

  .home-section4 {
    margin: 2rem;
    padding: 2rem;
  }

  .heading-text {
    font-size: 40px;
  }

  .heading {
    font-size: 35px;
  }

  .para {
    font-size: 18px;
  }

  .home-section2 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
}

@media (max-width: 600px) {
  .map-section svg {
    width: 110%;
  }
  #video {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .pattern-about {
    margin-top: 900px;
  }
  .pattern-footer {
    margin-top: 3000px;
    background-position-x: 200px;
  }
  .slider-heading {
    font-size: 26px;
  }

  .slider-btn {
    font-size: 10px;
    width: 159px;
    height: 38px;
  }

  .home-section4 {
    margin: 1rem;
    padding: 1rem;
  }

  .slider img {
    max-height: 50px;
  }

  /* .slider {
    height: 80px;
  } */
  .slick-slide {
    margin: 0 10px;
  }

  .slick-list {
    height: 10rem;
  }

  /* .slider img {
    width: 100px;
  } */
  .slider {
    height: 90px;
  }

  .slick-slide {
    margin: 0 20px;
  }

  .card {
    width: 265px;
    height: 270px;
    padding: 40px 50px;
  }

  .card-text {
    font-size: 20px;
  }

  .view-btn {
    font-size: 20px;
  }

  .heading {
    font-size: 30px;
  }

  .para {
    font-size: 16px;
  }

  .home-section2 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

@media (max-width: 550px) {
  .heading-text {
    font-size: 35px;
  }

  .heading-desc {
    font-size: 14px;
  }

  /* .slider img {
    width: 50px;
  } */
  .slider {
    height: 80px;
  }

  .slick-slide {
    margin: 0 10px;
  }

  .card {
    width: 245px;
    height: 250px;
    padding: 30px 40px;
  }

  .card-text {
    font-size: 16px;
  }

  .view-btn {
    font-size: 16px;
  }

  .heading {
    font-size: 25px;
  }

  .para {
    font-size: 14px;
  }

  .home-section4 {
    margin: 1rem;
    padding: 1rem;
  }
}


.carousel-img-wrapper {
  max-height: 800px; /* Adjust this value as needed */
  overflow: hidden;
}

.carousel-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  .carousel-img-wrapper {
    max-height: 500px; /* Adjust this value for mobile screens */
  }

  .content-1-img-section {
    margin: 2rem 0; /* Reduce margin on mobile */
  }
}
