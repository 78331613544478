.footer {
  background-color: #000 !important;
  display: flex;
  padding: 5rem 8rem;
  margin-top: -3rem;
  gap: 30px;
  justify-content: space-between;
  overflow: hidden;
  bottom: 0;
}

.pattern-footers {
  margin-left: auto ;
  width: 608px;
  height: 800px;
  background-repeat: no-repeat;
  background-position-y: 90px;
  background-blend-mode: multiply;
  opacity: 0.3;
  margin-top: 2450px;
  position: absolute;
  background-size: cover;

  left: 0px;
  right: 0px;
 
}
.menufooter {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 33px; 
  text-transform: capitalize;
}

.iconfooter {
  gap: 10px;
  align-items: center;
  display: flex;
}

.logo-cerification {
  margin: auto;
}

.logo-cerification img {
  height: 120px;
}

.footerHeading,
.footerDetail {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 33px;
  text-transform: capitalize;
}

.footerDetail-lower {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 33px;
  font-weight: 100;
}

.footerDetail {
  font-weight: 100;
}

.footerHeading {
  font-weight: 400;
}

.menu-list li {
  list-style-type: circle;
}

.menu-list-link {
  text-decoration: none;
  color: #fff;
}

.menu-list-link:hover {
  color: rgb(255, 207, 103);
}

.social-media {
  display: flex;
  gap: 1rem;
}

.social-icon {
  cursor: pointer !important;
}

.social-icon img {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1100px) {
  .footer {
    flex-direction: column;
    align-items: center;
    gap: 5rem;
  }
  .footerHeading,
  .footerDetail {
    font-size: 16px;
  }
  .footerDetail-lower {
    font-size: 16px;
  }
  .menufooter {
    font-size: 16px;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
  }
  .iconfooter img {
    width: 100%;
  }
  .logofooter {
    width: 200px;
  }
  .contactfooter {
    width: 100%;
    text-align: left;
  }
}

@media screen and (max-width: 627px) {
  .footer {
    padding: 50px;
    gap: 2rem;
  }
  .logofooter img {
    width: 150px;
  }
  .contactfooter {
    width: 100%;
    text-align: left;
  }
  .menufooter {
    font-size: 10px;
  }
  .footerHeading,
  .footerDetail {
    font-size: 10px;
  }
  .footerDetail-lower {
    font-size: 10px;
  }
}
