/* .review-form-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 25px;
  border-radius: 12px;
  background-color: lightsteelblue;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.review-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #000000;
}

.review-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #222;
  margin-bottom: 6px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #fff;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #77c9d4;
  box-shadow: 0 0 5px rgba(119, 201, 212, 0.3);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  height: 120px;
}

.submit-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #77c9d4;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  width: 100%;
}

.submit-button:hover {
  background-color: #58b2c2;
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .review-form-container {
    padding: 15px;
    margin: 10px;
  }

  .review-heading {
    font-size: 20px;
  }

  .form-group label {
    font-size: 13px;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 14px;
  }

  .submit-button {
    padding: 10px;
    font-size: 14px;
  }
} */


.review-form-container {
  max-width: 600px;
  margin: 20px auto;
  padding: 25px;
  border-radius: 12px;
  background-color: #2e2e2e; /* Dark background */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Slightly darker shadow for better contrast */
  font-family: "Open Sans", sans-serif;
  text-align: center;
  color: #f0f0f0; /* Light text color for better readability */
}

.review-heading {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #f0f0f0; /* Light text color */
}

.review-form {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: #ddd; /* Light grey text for labels */
  margin-bottom: 6px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #444; /* Darker border color */
  border-radius: 6px;
  font-size: 15px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background-color: #333; /* Dark input background */
  color: #f0f0f0; /* Light text color for inputs */
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #77c9d4; /* Accent color */
  box-shadow: 0 0 5px rgba(119, 201, 212, 0.5); /* Slightly lighter focus shadow */
  outline: none;
}

.form-group textarea {
  resize: vertical;
  height: 120px;
}

.submit-button {
  display: inline-block;
  padding: 12px 25px;
  background-color: #77c9d4; /* Accent color */
  color: #000; /* Text color for the button */
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  text-align: center;
  width: 100%;
}

.submit-button:hover {
  background-color: #58b2c2; /* Darker hover color */
  transform: translateY(-2px);
}

.submit-button:active {
  transform: translateY(0);
}

@media screen and (max-width: 767px) {
  .review-form-container {
    padding: 15px;
    margin: 10px;
  }

  .review-heading {
    font-size: 20px;
  }

  .form-group label {
    font-size: 13px;
  }

  .form-group input,
  .form-group textarea {
    padding: 10px;
    font-size: 14px;
  }

  .submit-button {
    padding: 10px;
    font-size: 14px;
  }
}
