.about-us-container {
  padding: 2rem;
  margin-top: 5rem;
  font-family: "Open Sans", sans-serif;
}

.oil-banner7 {
  margin-top: 11rem;
  margin-bottom: 3rem;
  width: 100%;
  height: 36vh; /* You can adjust this value as needed */
  border-radius: 10px;
  background: url("../../assets/aboutus/bgimage.jpg");
  background-size: cover; /* Fill the area without cropping */
  background-position: center; /* Keeps the image centered */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.5s ease; /* Smooth transition effect for scale */
 
}

.oil-banner7:hover {
  transform: scale(1.05); /* Slightly scale the image on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); /* Enhance shadow on hover */
}

.overlay7 {
  width: 100%;
  height: 36vh; /* Match the height of the banner */
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* Overlay on top of the image */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  transition: background 0.5s ease; /* Smooth transition for overlay */
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
  .oil-banner7 {
    height: 40vh; /* Adjust height if needed for mobile */
    margin-left: 9px;
  }

  .overlay7 {
    height: 40vh; /* Match the height of the banner in mobile view */
  }
}


.oil-title7 {
  text-align: center;
  padding: 0 20px;
  z-index: 1;
  animation: fadeInDown 2s ease-in-out;
}

.pattern-right {
  margin-left: auto !important;

  width: 500px;
  height: 550px;
  background-repeat: no-repeat;
  background-position-x: 90px;
  background-blend-mode: multiply;
  opacity: 0.5;
  margin-bottom: 100px;
  /* background-position-y:-80px ; */
  position: absolute;
  background-size: cover;
  left: 0px;
  right: 0px;
}

.oil-banner7:hover .overlay7 {
  background: rgba(0, 0, 0, 0.7); 
}

@media screen and (max-width: 768px) {
  .oil-banner7 {
    height: 40vh; 
    width: 390px;
    margin-top: 11rem;
  }

  .overlay7 {
    height: 40vh; 
  }
}

/* .pattern-left {
  width: 500px;
  height: 550px;
  background-repeat: no-repeat;
  background-position-x: -240px;
  background-position-y: 10px;
  background-blend-mode: multiply;
  background-position-y:-80px ;
  position: absolute;
  background-size: cover;
  margin-top: 800px;
  opacity: 0.5;
  left: 0px;
  right: 0px;
} */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-us-heading {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
}

.highlighted-text {
  color: #00d2d3;
  font-weight: 700;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(0, 210, 211, 0.7),
      0 0 20px rgba(0, 210, 211, 0.4);
  }
  to {
    text-shadow: 0 0 20px rgba(0, 210, 211, 0.9),
      0 0 30px rgba(0, 210, 211, 0.6);
  }
}

.about-us-content {
  display: flex;
  flex-wrap: wrap;
  margin: 3rem 0;
}

.about-us-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.about-us-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-us-decs {
  color: #fffefe;
  font-size: 20px;
  font-weight: 400;
  margin: 2rem 0;
  padding: 0 2rem;
  text-align: justify;
  line-height: 1.6;
}

@media screen and (max-width: 1100px) {
  .about-us-decs {
    font-size: 18px;
    padding: 0 1.5rem;
  }
  .pattern-left {
    margin-top: 1450px;
  }
}

@media screen and (max-width: 945px) {
  .about-us-decs {
    font-size: 16px;
    padding: 0 1rem;
  }
  .pattern-left {
    margin-top: 1650px;
  }
}

@media screen and (max-width: 767px) {
  .pattern-right {
    margin-top: 750px;
  }
  .pattern-left {
    margin-top: 1850px;
  }
  .about-us-content {
    flex-direction: column;
  }

  .about-us-image,
  .about-us-text {
    width: 100%;
  }
}

.carousel-img-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-img {
  height: 700px;
  width: auto;
}

.breadcrumb {
  margin-bottom: 2rem;
}
.paras {
  color: #ffffff;
  font-family: "Baskerville";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  animation: fadeInLeft 2s ease-in-out;
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(40px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
