.language-switcher {
  display: flex; /* Use flexbox for layout */
  flex-direction: row; /* Default to horizontal layout */
}

.language-switcher.vertical {
  flex-direction: column; /* Change to vertical layout for mobile */
}

.language-button {
  background-color: #1c1c1dfa;
  border: none;
  color: white;
  padding: 5px 10px; /* Increase the right padding */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px 3px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.language-button:hover {
  background-color: #121213;
}

/* Media query for mobile view */
@media screen and (max-width: 768px) {
  .language-switcher {
    flex-direction: column; /* Change to vertical layout */
  }
}
