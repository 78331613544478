.menu-containers {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 99999;
    width: 80px;
    height: 80px;
    background-color: #2f9c5c;;
    border-radius: 40px;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.5s ease;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .coffee-icon {
    position: relative;
    width: 50px;
    height: 50px;
    margin-left: 15px;
    transition: all 0.5s ease;
  }
  
  .cup {
    position: absolute;
    width: 35px;
    height: 28px;
    background-color: #ffffff;
    border-radius: 0 0 15px 15px;
    bottom: 10px;
    left: 7.5px;
  }
  
  .handle {
    position: absolute;
    width: 12px;
    height: 16px;
    border: 3px solid #ffffff;
    border-left: none;
    border-radius: 0 10px 10px 0;
    right: -12px;
    top: 5px;
  }
  
  .saucer {
    position: absolute;
    width: 45px;
    height: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    bottom: 5px;
    left: 2.5px;
  }
  
  .steam {
    position: absolute;
    width: 8px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.5s ease;
  }
  
  .steam-1 { left: 10px; top: 5px; }
  .steam-2 { left: 20px; top: 0; }
  .steam-3 { left: 30px; top: 5px; }
  
  .menu-text {
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
    opacity: 0;
    transition: all 0.5s ease;
    white-space: nowrap;
  }
  
  .menu-containers:hover {
    width: 200px;
    background-color: #1f72b6;
  }
  
  .menu-containers:hover .coffee-icon {
    transform: translateX(-5px);
  }
  
  .menu-containers:hover .steam {
    opacity: 0.7;
    transform: translateY(-10px);
  }
  
  .menu-containers:hover .steam-1 { transition-delay: 0.1s; }
  .menu-containers:hover .steam-2 { transition-delay: 0.2s; }
  .menu-containers:hover .steam-3 { transition-delay: 0.3s; }
  
  .menu-containers:hover .menu-text {
    opacity: 1;
    transform: translateX(0);
  }
  
  @keyframes wiggle {
    0%, 100% { transform: rotate(0); }
    25% { transform: rotate(5deg); }
    75% { transform: rotate(-5deg); }
  }
  
  .menu-containers:hover .cup {
    animation: wiggle 0.5s ease-in-out;
  }