/* .menu-container {
  display: flex;
  height: 100vh;
  transition: margin-left 0.3s ease;
  position: relative;
  background-color: white;
  color: black;
}

.sidebar {
  width: 250px;
  background-color: black;
  color: #ecf0f1;
  padding: 20px;
  position: absolute;
  top: 150px;
  left: 0;
  height: calc(100% - 9rem);
  overflow-y: auto;
  transition: transform 0.3s ease;
}

.sidebar.hidden {
  transform: translateX(-100%);
}
.menu-header {
  display: flex;
  justify-content: space-between;
}
.sidebar-close {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.sidebar-toggle {
  display: none;

  margin-right: 15px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border: none;
  padding: 10px;
  text-align: center;

  cursor: pointer;
  height: 40px;
}
.content-header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}
.menu-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ecf0f1;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: #34495e;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #1abc9c;
}

.menu-item.active {
  background-color: #16a085;
  font-weight: bold;
}

.content {
  margin-left: 250px;
  margin-top: 10rem;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  padding-top: 0;
}

.content.expanded {
  margin-left: 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 20px;
}

.product-card {
  background: white;
  border: 0px solid #ddd;
  min-width: 340px;
  max-width: 350px;
  border-radius: 0px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 250px;
}

.product-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0;
}

.product-description {
  font-size: 1rem;
}
.size-options {
  display: flex;
  gap: 10px;
  margin: 10px;
  justify-content: center;
}

.size-option {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #7a7993;
  color: #fffefe;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.size-option:hover {
  background-color: lightblue;
  border-color: #aaa;
}

.size-option.selected {
  background-color: #1abc9c;
  color: #fff;
  border-color: #1abc9c;
}

.product-price {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: grey;
}
.sort-section {
  margin-left: 5px;
  display: flex;
  outline: #fff;
  margin-bottom: 15px;
}
.sort-section p {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
  padding-left: 2px;
}
.sort-selection {
  width: 250px;
  margin-top: 0;

  box-sizing: border-box;
  letter-spacing: 0.05rem;
  outline: 0 none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: sans-serif;
  font-size: 100%;

  padding: 0 30px 0 10px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  line-height: 36px;
  height: 36px;
  background: #fff;
  margin: 0 5px 5px 0;
}

.add-to-cart-btn {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.add-to-cart-btn:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}
.sidebar::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track,
.content::-webkit-scrollbar-track {
  background: #34495e;
}

.sidebar::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #34495e;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.content::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
.add-to-cart-button {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}
.add-to-cart-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}
@media (min-width: 1580px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .content.expanded {
    margin-left: 250px;
  }
  .sidebar.hidden {
    transform: translateX(0%);
  }
  .sidebar-close {
    display: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}
@media (max-width: 1024px) {
  .sidebar {
    display: block;
    width: 100%;
    top: 150px;
    height: calc(100vh - 10rem);
  }

  .sidebar.hidden {
    transform: translateX(-100%);
  }

  .sidebar-toggle {
    display: block;
   
  }

  .content {
    margin-left: 0;
    margin-top: 10rem;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .sidebar {
    width: 100%;
    top: 150px;
    height: calc(100vh - 10rem);
  }

  .sidebar.hidden {
    transform: translateX(-100%);
  }

  .sidebar-toggle {
    display: block;
  }

  .content {
    margin-left: 0;
    padding: 10px;
  }

  .product-card {
    padding: 0.5rem;
  }

  .product-name {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
  .product-card {
    margin-left: auto;
    margin-right: auto;
  }
  .heading-text {
    font-size: 1.5rem;
  }

  .heading-desc {
    font-size: 0.875rem;
  }

  .product-name {
    font-size: 0.875rem;
  }
} */


.menu-container {
  display: flex;
  height: 100vh;
  transition: margin-left 0.3s ease;
  position: relative;
  background-color: white;
  color: black;
}

.sidebar {
  width: 250px;
  background-color: black;
  color: #ecf0f1;
  padding: 20px;
  position: absolute;
  top: 150px;
  left: 0;
  height: calc(100% - 9rem);
  overflow-y: auto;
  transition: transform 0.3s ease;
}

.sidebar.hidden {
  transform: translateX(-100%);
}
.menu-header {
  display: flex;
  justify-content: space-between;
}
.sidebar-close {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.sidebar-toggle {
  display: none;

  margin-right: 15px;
  background-color: #2c3e50;
  color: #ecf0f1;
  border: none;
  padding: 10px;
  text-align: center;

  cursor: pointer;
  height: 40px;
}
.content-header {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
}
.menu-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ecf0f1;
}

.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-cart-btn {
  display: none;
  padding: 2px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .mobile-cart-btn {
    display: block;
  }
  
  .content-header {
    padding: 0 10px;
  }
}

.menu-item {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 1rem;
  border-radius: 4px;
  background-color: #34495e;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #1abc9c;
}

.menu-item.active {
  background-color: #16a085;
  font-weight: bold;
}

.content {
  margin-left: 250px;
  margin-top: 10rem;
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  padding-top: 0;
}

.content.expanded {
  margin-left: 0;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  gap: 20px;
}

.product-card {
  background: white;
  border: 0px solid #ddd;
  min-width: 340px;
  max-width: 350px;
  border-radius: 0px;
  text-align: center;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease;
}

.product-card:hover {
  transform: scale(1.05);
}

.product-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-height: 250px;
}

.product-name {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0;
}

.product-description {
  font-size: 1rem;
}
.size-options {
  display: flex;
  gap: 10px;
  margin: 10px;
  justify-content: center;
}

.size-option {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #7a7993;
  color: #fffefe;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.size-option:hover {
  background-color: lightblue;
  border-color: #aaa;
}

.size-option.selected {
  background-color: #1abc9c;
  color: #fff;
  border-color: #1abc9c;
}

.product-price {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: grey;
}
.sort-section {
  margin-left: 5px;
  display: flex;
  outline: #fff;
  margin-bottom: 15px;
}
.sort-section p {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: bold;
  padding-left: 2px;
}
.sort-selection {
  width: 250px;
  margin-top: 0;

  box-sizing: border-box;
  letter-spacing: 0.05rem;
  outline: 0 none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: sans-serif;
  font-size: 100%;

  padding: 0 30px 0 10px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  line-height: 36px;
  height: 36px;
  background: #fff;
  margin: 0 5px 5px 0;
}

.add-to-cart-btn {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}

.add-to-cart-btn:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}
.sidebar::-webkit-scrollbar,
.content::-webkit-scrollbar {
  width: 5px;
}

.sidebar::-webkit-scrollbar-track,
.content::-webkit-scrollbar-track {
  background: #34495e;
}

.sidebar::-webkit-scrollbar-thumb,
.content::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #34495e;
}

.sidebar::-webkit-scrollbar-thumb:hover,
.content::-webkit-scrollbar-thumb:hover {
  background-color: #fff;
}
.add-to-cart-button {
  background-color: #16a085;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin: 1rem 0;
}
.add-to-cart-button:hover {
  background-color: #1abc9c;
  transform: scale(1.05);
}
@media (min-width: 1580px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1024px) {
  .content.expanded {
    margin-left: 250px;
  }
  .sidebar.hidden {
    transform: translateX(0%);
  }
  .sidebar-close {
    display: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
  }
}
@media (max-width: 1024px) {
  .sidebar {
    display: block;
    width: 100%;
    top: 150px;
    height: calc(100vh - 10rem);
  }

  .sidebar.hidden {
    transform: translateX(-100%);
  }

  .sidebar-toggle {
    display: block;
   
  }

  .content {
    margin-left: 0;
    margin-top: 10rem;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .sidebar {
    width: 100%;
    top: 150px;
    height: calc(100vh - 9rem);
  }

  .sidebar.hidden {
    transform: translateX(-100%);
  }

  .sidebar-toggle {
    display: block;
  }

  .content {
    margin-left: 0;
    padding: 10px;
  }

  .product-card {
    padding: 0.5rem;
  }

  .product-name {
    font-size: 1rem;
  }
}

@media (max-width: 576px) {
  .product-grid {
    grid-template-columns: 1fr;
  }
  .product-card {
    margin-left: auto;
    margin-right: auto;
  }
  .heading-text {
    font-size: 1.5rem;
  }

  .heading-desc {
    font-size: 0.875rem;
  }

  .product-name {
    font-size: 0.875rem;
  }
}
